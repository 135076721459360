import { Button } from "../Button";
import { motion } from "framer-motion";

import "./Resume.css"

interface Button {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
    type:string;
}

interface ResumeContent {
    text: string;
    title: string
}

interface ResumeData {
    title: string;
    textColor: string;
    backgroundImage:string;
    content: ResumeContent[];
    buttons: Button[];
}

interface ResumeProps {
    data: ResumeData;
}

export function Resume({ data }: ResumeProps) {
    return (
        <section className="resume" style={{ color: data.textColor, backgroundImage: `url(${data.backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
            <div className="resume-container">
                <header>
                    <h2>{data.title}</h2>
                </header>
                <div className="content">
                    {data.content.map((content, index) => (
                        <article className="info" key={index}>
                            {content.title && (
                                <p style={{  display: 'inline-block' }}>{content.title}</p>
                            )}
                            <p>{content.text}</p>
                        </article>
                    ))}
                </div>

                <Button data={data} />
            </div>
        </section>

    )
}