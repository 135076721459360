import { Button } from "../Button";
import { motion } from "framer-motion";

import "./AligneIcons.css"

interface Buttons {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
    type:string;
}

interface AligneIcons {
    icon: string;
    title: string;
    content: string
}

interface AligneIconsData {
    title: string;
    textColor: string;
    icons: AligneIcons[];
    buttons: Buttons[];
}

interface AligneIconsProps {
    data: AligneIconsData;
}

export function AligneIcons({ data }: AligneIconsProps) {
    return (
        <section className="aligneIcons">
            <div className="aligneIcons-container" style={{ color: data.textColor }}>
                <h2 style={{ WebkitTextStroke: "1px #000" }}>{data.title}</h2>
                <div className="items">
                    {data.icons.map((icon) => (
                        <div className="card">
                            <img src={icon.icon} alt={icon.title} />
                            <div className="content">
                                <motion.div
                                    animate={{
                                        scale: [1, 1.05, 1], // Alterna entre o tamanho normal e 1.05
                                    }}
                                    transition={{
                                        duration: 1.5, // Duração da animação
                                        repeat: Infinity, // Repete infinitamente
                                        repeatType: "reverse", // Reverte para criar o efeito de pulsação
                                    }}
                                >
                                    <h3>{icon.title}</h3>
                                </motion.div>
                                <p>{icon.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <Button data={data} />
            </div>
        </section>
    )
}