import { Helmet } from 'react-helmet';
import { Contact } from "../Components/Contact";
import { Cta } from "../Components/Cta";
import { NavBar } from "../Components/NavBar";
import { SideBar } from "../Components/SideBar";

import data from "../data.json";

export function ContactPage() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const ctaData = data.ContactPage.cta;
    const contactData = data.ContactPage.contact;

    return (
        <div className="contactPage" style={{ backgroundColor: data.ContactPage.backgroundColor }}>
            <Helmet>
                <title>Contato | Delleprane Studio</title>
                <meta name="description" content="Entre em contato com o Delleprane Studio para saber mais sobre nossos serviços e como podemos ajudar no crescimento do seu negócio." />
                <meta name="keywords" content="contato, estúdio de design, desenvolvimento web, marketing digital, falar conosco" />
                <meta property="og:title" content="Contato | Delleprane Studio" />
                <meta property="og:description" content="Fale com o Delleprane Studio para discutir projetos e encontrar soluções criativas para seu negócio." />
                <meta property="og:url" content="https://www.delleprane.com.br/contact" />
            </Helmet>

            <div className="contactPage-container">
                <NavBar navData={navData} />
                <Contact data={contactData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
