import { Button } from "../Button";

import "./Price.css"

interface Button {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
    type:string;
}

interface PriceModules {
    title: string;
    subTitle: string;
    highlithColor: string;
    price: string;
    bg: string;
    aligne:string
}

interface PriceData {
    title: string;
    subTitle: String;
    border:string;
    textColor: string;
    backgroundColor: string;
    modules: PriceModules[];
    buttons: Button[];
}

interface PriceProps {
    data: PriceData;
}

export function Price({ data }: PriceProps) {
    return (
        <div className="price" style={{color: data.textColor }}>
            <div className="price-container">
                <div className="info">
                    <h2 style={{WebkitTextStroke: "1px #000"}}>{data.title}</h2>
                    <p>{data.subTitle}</p>
                </div>

                <div className="modules">
                    {data.modules.map((module) => (
                        <div className={`module ${module.aligne === 'left' ? 'left' : 'right'}`} style={{ backgroundColor: module.bg, border:`2px solid ${data.border}`
                    }}>
                            <h2 style={{ color: module.highlithColor,WebkitTextStroke: "1px #000" }}>{module.title}</h2>
                            <p>{module.subTitle}</p>
                            <p>{module.price}</p>
                        </div>
                    ))}
                </div>
                <Button data={data} />
            </div>
        </div>
    )
}