import { Button } from "../Button";
import "./AligneText.css"

interface AligneData {
    textColor: string;
    image: string;
    title: string;
    texts: { aligne: string; content: string }[];
    buttons: { textColor: string; title: string; borderColor: string; link: string, type:string }[];
    button?: { textColor: string; title: string; borderColor: string; link: string, type:string }; 
}


interface DataProps {
    data: AligneData;
}

export function AligneText({ data }: DataProps) {
    return (
        <div className="aligne-text">
            <div className="aligne-text-container">
                <div className="content" style={{ color: data.textColor }}>
                    <div className="title">
                    <h3>{data.title}</h3>
                    <img src={data.image} alt="Descrição da imagem" />
                    </div>
                    <div className="info">
                        {data.texts.map((text, index) => (
                            <p key={index}>{text.content}</p> 
                        ))}
                    </div>
                </div>
                
                <Button data={data}/>
            </div>
        </div>
    );
}

