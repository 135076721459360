import { Button } from "../Button";
import "./Cta.css"

interface ButtonData {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
    type:string;
}

interface CtaData {
    backgroundColor: string;
    textColor: string;
    title: string;
    content: string;
    highlightColor:string;
    buttons: ButtonData[];
}

interface CtaProps {
    data: CtaData;
}

export function Cta({ data }: CtaProps) {
    return (
        <section className="cta" aria-labelledby="cta-title" role="region">
            <div className="cta-container">
                <div
                    className="content"
                    style={{ color: data.textColor }}
                    aria-describedby="cta-content"
                >
                    <h2 id="cta-title" style={{color:data.highlightColor,  WebkitTextStroke: "1px #000" }}>{data.title}</h2>
                    <p id="cta-content">{data.content}</p>
                    <Button data={data} />
                </div>
            </div>
        </section>
    );
}
