import { Helmet } from 'react-helmet';
import { Cta } from "../Components/Cta";
import { Feature } from "../Components/Feature";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Portfolio } from "../Components/Portifolio";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { AligneIcons } from '../Components/AligneIcons';
import { AligneText } from '../Components/AligneText';

export function LandingPages() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const heroData = data.Landings.hero;
    const aligneData = data.Landings.about;
    const featureData = data.Landings.feature;
    const portfolioData = data.Landings.portfolio;
    const aligneIconsData = data.Landings.aligneIcons;
    const workFlowData = data.Landings.workFlow;
    const ctaData = data.Landings.cta;

    return (
        <div className="sites" style={{ backgroundColor: data.Restyling.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Delleprane Studio</title>
                <meta name="description" content="Conheça nossos projetos de desenvolvimento de sites, criados com autenticidade, liberdade criativa e diversidade de estilos. Veja nossos portfólios e casos de sucesso." />
                <meta name="keywords" content="desenvolvimento de sites, design de sites, criação de sites, portfólio, projetos criativos" />
                <meta property="og:title" content={heroData.title} />
                <meta property="og:description" content="Explore os projetos criativos da Delleprane Studio. Criamos sites que refletem a identidade e a criatividade de nossos clientes." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/sites" />
            </Helmet>

            <div className="sites-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <AligneText data={aligneData} />
                <Feature data={featureData} />
                <Portfolio data={portfolioData} />
                <AligneIcons data={aligneIconsData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
