import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Tipagem para as props
interface DropdownItem {
  name: string;
  icon?: string;
  link: string;
}

interface MenuItemProps {
  name: string;
  link: string;
  content?: DropdownItem[];
}

interface HamburgerMenuProps {
  items: MenuItemProps[];
  textColor: string;
  backgroundColor: string;
}

// Componente do Hamburger Menu
export const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ items, textColor, backgroundColor }) => {
  const [isOpen, setIsOpen] = useState(false); // Estado para abrir/fechar o menu
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null); // Estado para dropdowns abertos

  const toggleMenu = () => setIsOpen(!isOpen);

  const toggleDropdown = (name: string) => {
    setActiveDropdown((prev) => (prev === name ? null : name));
  };

  useEffect(() => {
    // Verifica se o modal está aberto
    if (isOpen) {
      const handleScroll = () => {
        setIsOpen(false);
      };

      window.addEventListener("scroll", handleScroll);

      // Limpa o evento ao desmontar o componente
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isOpen]);

  return (
    <div style={{zIndex:"11"}}>
      {/* Botão do hambúrguer */}
      <h2 onClick={toggleMenu} style={{ fontSize: "24px", backgroundColor: backgroundColor, color: textColor }}>
        ☰
      </h2>

      {/* Menu principal */}
      <AnimatePresence >
        {isOpen && (
          <motion.nav
          className="modal"
            initial={{ opacity: 0, x: 100 }} // Começando fora da tela, à direita
            animate={{ opacity: 1, x: 0 }} // Movendo para o centro
            exit={{ opacity: 0, x: 100 }} // Retornando à direita
            transition={{ type: "spring", stiffness: 50 }}
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              width: '150px',
              height: "100%",
              backgroundColor: backgroundColor,
              color: textColor,
              padding: "20px",
              textAlign: "right"
            }}
          >
            <h3 onClick={toggleMenu} style={{  backgroundColor: backgroundColor, color: textColor }}>
              X
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {items.map((item) => (
                <li key={item.name} style={{ marginBottom: "15px", }}>
                  {item.content ? (
                    <>
                      <img loading="lazy" onClick={() => toggleDropdown(item.name)} src="/images/icon-arrow-white.png" alt="" style={{  transform: activeDropdown ? "rotate(180deg)" : "rotate(90deg)", width: "15px" }}  />
                      <button
                        onClick={() => toggleDropdown(item.name)}
                        style={{
                          color: textColor,
                          background: "none",
                          border: "none",
                        }}
                      >
                        {item.name}
                      </button>
                      <AnimatePresence>
                        {activeDropdown === item.name && (
                          <motion.ul
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            style={{
                              listStyle: "none",
                              marginTop: "20px",
                              padding: 0
                            }}
                          >
                            {item.content.map((dropdownItem) => (
                              <li key={dropdownItem.name} style={{ marginBottom: "10px" }}>
                                <a
                                  href={dropdownItem.link}
                                  style={{
                                    textDecoration: "none",
                                    color: "#ccc",
                                  }}
                                >
                                  {dropdownItem.name}
                                </a>
                              </li>
                            ))}
                          </motion.ul>
                        )}
                      </AnimatePresence>
                    </>
                  ) : (
                    <a
                      href={item.link}
                      style={{
                        color: textColor,
                        textDecoration: "none"
                      }}
                    >
                      {item.name}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  );
};
