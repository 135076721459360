import React from 'react';
import logo from './logo.svg';
import './App.css';

import { RoutesT } from './Routes';

function App() {
  return (
    <div className="App">
      <div className="app-container">
        <RoutesT />
      </div>
    </div>
  );
}

export default App;
