import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

import './Portfolio.css';
import { Button } from "../Button";

interface Button {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
}

interface PortfolioItem {
    image?: string;
    content?: string;
    link?: string;
    align?: string;
}

interface PortfolioCategory {
    items?: PortfolioItem[];
}

interface PortfolioData {
    title: string;
    textColor: string;
    type: string;
    highlightColor: string;
    backgroundColorSecundary: string;
    categories: PortfolioCategory;
    buttons: Button[];
}

interface PortfolioProps {
    data: PortfolioData;
}

export function Portfolio({ data }: PortfolioProps) {

    return (
        <section className="portfolio">
            <div className="portfolio-container">
                <h2 style={{ color: data.textColor, WebkitTextStroke: "1px #000" }}>{data.title}</h2>
                <div className="projects">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        autoplay={true}
                        navigation={true}
                        modules={[Navigation, Autoplay]}
                        className="mySwiper"
                    >
                        {data.categories.items?.map((item) => (
                            <SwiperSlide>
                                <div className="content-card">
                                    <div className="content">
                                        <p style={{ color: data.textColor }}>{item.content}</p>
                                        <a href={item.link} style={{color:data.highlightColor}} target='_blank'>Navegar pelo Projeto</a>
                                    </div>
                                    <img src={item.image} alt="imagem de cases produzidos por delleprane studio" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}
