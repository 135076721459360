import "./About.css"

interface AboutData {
    title: string;
    contentPast: string;
    contentWork: string;
    contentDell: string;
    image: string;
    textColor: string
}

interface AboutProps {
    data: AboutData;
}

export function About({ data }: AboutProps) {
    return (
        <section className="aboutC" style={{ color: data.textColor }}>
            <div className="aboutC-container">
                <header>
                    <h2>{data.title}</h2>
                </header>
                <article className="content">
                    <div className="center">
                        <p>{data.contentPast}</p>
                        <div>
                            <img
                                src={data.image}
                                alt="Logo da Delleprane Studio, representando a criatividade e personalidade da empresa"
                                loading="lazy"
                            />
                        </div>
                        <p>{data.contentWork}</p>
                    </div>
                    <p>{data.contentDell}</p>
                </article>
            </div>
        </section>
    )
}
