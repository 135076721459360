import { Helmet } from 'react-helmet';
import { Contact } from "../Components/Contact";
import { Differential } from "../Components/Differential/Differential";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Price } from "../Components/Price";
import { Resume } from "../Components/Resume/Resume";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { Cta } from '../Components/Cta';

export function Freelance() {
    const navData = data.NavBar;
    const sideData = data.SideBar;
    const heroData = data.Freelance.hero;
    const resumeData = data.Freelance.resume;
    const differentialData = data.Freelance.differential;
    const ctaData = data.Freelance.cta;
    const workFlowData = data.Freelance.workFlow;
    const contactData = data.Freelance.contact;
    const priceData = data.Freelance.price;

    return (
        <div className="freelance" style={{ backgroundColor: data.Freelance.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Freelance Services</title>
                <meta name="description" content="Conheça os serviços freelancers oferecidos por Delleprane Studio, com foco em criatividade, inovação e soluções personalizadas para cada cliente." />
                <meta name="keywords" content="freelance, serviços freelancer, design gráfico, desenvolvimento web, marketing digital, soluções personalizadas" />
                <meta property="og:title" content={heroData.title} />
                <meta property="og:description" content="Explore os serviços freelancers da Delleprane Studio, onde criamos soluções criativas e inovadoras para nossos clientes." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/freelance" />
            </Helmet>

            <div className="freelance-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <Resume data={resumeData} />
                <Differential data={differentialData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData}/>
                <Contact data={contactData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
