import { AligneText } from "../Components/AligneText";
import { Carousel } from "../Components/Carousel";
import { Cta } from "../Components/Cta";
import { DropDownMenu } from "../Components/DropdownMenu/DropdownMenu";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { Helmet } from 'react-helmet';


export function Home() {
    const navData = data.NavBar
    const heroData = data.Home.hero
    const sideData = data.SideBar
    const navigationData = data.Home.navigation
    const aligneTextData = data.Home.about
    const dropdownData = data.Home.dropdownMenu
    const workFlowData = data.Home.workFlow
    const ctaData = data.Home.cta

    return (
        <div className="home" style={{ backgroundColor: data.Home.backgroundColor }}>
            <Helmet>
                <title>{heroData.title} | Delleprane Studio</title>
                <meta name="description" content={heroData.subTtitle} />
                <meta name="keywords" content="criatividade, design, inovação, projetos, autenticidade, Delleprane Studio" />
            </Helmet>
            <div className="home-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <Carousel data={navigationData} />
                <AligneText data={aligneTextData} /> 
                <DropDownMenu data={dropdownData}/>
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    )
}