import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./SideBar.css";

interface ContactData {
    icon: string;
    content: string;
    link: string;
    type: string;
}

interface ContentItemData {
    name: string;
    icon: string;
    link: string;
}

interface SectionsData {
    name: string;
    link: string;
    textColor: string;
    content?: ContentItemData[];
}

interface NavData {
    title: string;
    sections?: SectionsData[];
    section?: SectionsData[];
}

interface SideData {
    icon: string;
    textColor: string;
    backgroundColor: string;
    contact: ContactData[];
    navItens: NavData[];
}

interface SideProps {
    sideData: SideData;
}

export function SideBar({ sideData }: SideProps) {
    const [isOpen, setIsOpen] = useState(false); // Estado para abrir/fechar o menu
    const [activeDropdown, setActiveDropdown] = useState<string | null>(null); // Estado para dropdowns abertos

    const toggleDropdown = (name: string) => {
        setActiveDropdown((prev) => (prev === name ? null : name));
    };

    const [modalContent, setModalContent] = useState<ContentItemData[] | null>(null);

    const openModal = (content: ContentItemData[]) => {
        setModalContent(content);
    };

    const closeModal = () => {
        setModalContent(null);
    };

    useEffect(() => {
        if (modalContent) {
            const handleScroll = () => {
                closeModal();
            };
            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [modalContent]);

    return (
        <div className="sideBar">
            <div
                className="sideBar-container"
                style={{
                    color: sideData.textColor,
                    backgroundColor: sideData.backgroundColor,
                }}
            >
                <div className="contact-itens">
                    {sideData.contact.map((contact, index) => {
                        let content;

                        if (contact.type === "local") {
                            content = (
                                <div key={index} style={{ color: sideData.textColor }} className="local">
                                    <img loading="lazy" src={contact.icon} alt={`Ícone de ${contact.type}`}  />
                                    {contact.content}
                                </div>
                            );
                        } else if (contact.type === "telephone") {
                            content = (
                                <div className="phone">
                                    <a
                                        key={index}
                                        className="phone"
                                        target="_blank"
                                        href={contact.link}
                                        style={{ color: sideData.textColor }}
                                    >
                                        <img loading="lazy" src={contact.icon} alt={contact.content} />
                                        {contact.content}
                                    </a>
                                </div>
                            );
                        } else if (contact.type === "email") {
                            content = (
                                <div className="email">
                                    <a
                                        key={index}
                                        href={`mailto:${contact.content}`}
                                        style={{ color: sideData.textColor }}
                                    >
                                        <img loading="lazy" src={contact.icon} alt={contact.content} />
                                        {contact.content}
                                    </a>
                                </div>
                            );
                        }

                        return content;
                    })}
                </div>
                <div className="logo">
                    <a href="/" className="image">
                        <img loading="lazy" src={sideData.icon} alt="logo Delleprane Studio" />
                    </a>
                </div>
                <div className="site-map">
                    {sideData.navItens.map((iten,index) => (
                        <nav key={index} className="content">
                            <h3>{iten.title}</h3>
                            <ul>
                                {iten.sections?.map((section) => (
                                    <>
                                        {section.content ? (
                                            <div className="drop">
                                                <img
                                                    loading="lazy"
                                                    onClick={() => toggleDropdown(section.name)}
                                                    src="/images/icon-arrow-white.png"
                                                    alt={`Abrir submenu de ${section.name}`}
                                                    style={{ transform: activeDropdown ? "rotate(180deg)" : "rotate(90deg)", width: "15px" }}
                                                />
                                                <button
                                                    onClick={() => toggleDropdown(section.name)}
                                                    style={{
                                                        color: section.textColor,
                                                        background: "none",
                                                        border: "none",
                                                    }}
                                                    aria-expanded={activeDropdown === section.name ? "true" : "false"}
                                                >
                                                    {section.name}
                                                </button>
                                                <AnimatePresence>
                                                    {activeDropdown === section.name && (
                                                        <motion.ul
                                                            initial={{ opacity: 0, y: -10 }}
                                                            animate={{ opacity: 1, y: 0 }}
                                                            exit={{ opacity: 0, y: -10 }}
                                                            style={{
                                                                listStyle: "none",
                                                                marginTop: "20px",
                                                                padding: 0,
                                                            }}
                                                        >
                                                            {section.content.map((dropdownItem) => (
                                                                <li key={dropdownItem.name} style={{ marginBottom: "10px" }}>
                                                                    <a
                                                                        href={dropdownItem.link}
                                                                        style={{
                                                                            textDecoration: "none",
                                                                            color: "#ccc",
                                                                        }}
                                                                        title={`Ir para ${dropdownItem.name}`}
                                                                    >
                                                                        {dropdownItem.name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </motion.ul>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                        ) : (
                                            <li>
                                                <a
                                                    href={section.link}
                                                    style={{
                                                        color: section.textColor,
                                                        textDecoration: "none",
                                                    }}
                                                    onClick={closeModal}
                                                >
                                                    {section.name}
                                                </a>
                                            </li>
                                        )}
                                    </>
                                ))}
                            </ul>
                        </nav>
                    ))}
                </div>
            </div>
        </div>
    );
}
