import { motion } from "framer-motion";
import { Button } from "../Button";

import "./Differencial.css"

interface Button {
    title: string;
    borderColor: string;
    link: string;
    textColor: string;
    type:string;
}

interface DifferencialContent {
    text: string;
    icon: string;
    align: string
}

interface DifferencialData {
    title: string;
    textColor: string;
    content: DifferencialContent[];
    buttons: Button[];
}

interface DifferencialProps {
    data: DifferencialData;
}

export function Differential({ data }: DifferencialProps) {
    return (
        <section
            className="differential"
            style={{
                color: data.textColor,
            }}
        >
            <div className="differential-container">
                <header>
                    <h2>{data.title}</h2>
                </header>
                <div className="content-container">
                    {data.content.map((content, index) => (
                        <article className="content" key={index}>
                            <motion.div
                                key={index}
                                className={`info ${content.align === 'left' ? 'left-class' : 'right-class'}`}
                                style={{ color: data.textColor }}
                                initial={{ opacity: 0 }} // Inicialmente, o texto está invisível
                                whileInView={{ opacity: 1 }} // Quando estiver na visão, o texto se torna visível
                                transition={{ duration: 0.8, ease: 'easeInOut' }} // Define a duração da animação e o tipo de transição
                            >
                                <h3>{content.text}</h3>
                                <img loading="lazy" src={content.icon} alt={`Ícone representando ${content.text}`} />
                            </motion.div>
                        </article >
                    ))}
                </div>
                <footer>
                    <Button data={data} />
                </footer>
            </div>
        </section>
    )
}