import { useState } from "react";
import { motion } from "framer-motion";

import "./DropDownMenu.css"

interface DropdownCardsData {
    title: string;
    content: string;
    textColor: string;
    color: string;
    backgroundColor: string;
}

interface DropdownData {
    title: string;
    textColor: string;
    cards: DropdownCardsData[]
}

interface DropdownProps {
    data: DropdownData;
}

export function DropDownMenu({ data }: DropdownProps) {
    const [openCardIndex, setOpenCardIndex] = useState<number | null>(null);

    const toggleCard = (index: number) => {
        setOpenCardIndex(openCardIndex === index ? null : index);
    };

    const renderMobileCardHeader = (item: DropdownCardsData, index: number) => (
        <div
            className="mobile-card-header"
            onClick={() => toggleCard(index)}
            style={{
                backgroundColor: item.backgroundColor,
                color: item.textColor,
            }}
        >
                <h2>{item.title}</h2>
                <span
                    aria-expanded={openCardIndex === index ? "true" : "false"}
                    aria-controls={`card-content-${index}`}
                    style={{
                        transform: openCardIndex === index ? "rotate(180deg)" : "rotate(360deg)",
                    }}
                >
                    <img
                        loading="lazy"
                        src="./images/pink-arrow.png"
                        alt="Ícone de seta para expandir ou retrair o card"
                    />
                </span>
        </div>
    );

    const renderCardContent = (item: DropdownCardsData, index: number) => (
        openCardIndex === index && (
            <div
                id={`card-content`}
                className="mobile-card-content"
                style={{
                    backgroundColor: item.backgroundColor,
                    color: item.textColor,
                }}
            >
                <p>{item.content}</p>
            </div>
        )
    );

    return (
        <section className="dropdown">
            <div className="dropdown-container" style={{ color: data.textColor }}>
                <header>
                    <h3 style={{ WebkitTextStroke: "1px #000" }}>{data.title}</h3>
                </header>
                {data.cards.map((item, index) => (
                    <div key={index} className="card" style={{ border: `4px solid ${item.color}`, backgroundColor: item.backgroundColor }}>
                        {renderMobileCardHeader(item, index)}
                        {renderCardContent(item, index)}
                    </div>
                ))}
            </div>
        </section>
    );
}
