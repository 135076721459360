import { Helmet } from 'react-helmet';
import { About } from "../Components/About";
import { Contact } from "../Components/Contact";
import { Hero } from "../Components/Hero";
import { NavBar } from "../Components/NavBar";
import { Price } from "../Components/Price";
import { SideBar } from "../Components/SideBar";
import { WorkFlow } from "../Components/WorkFlow";

import data from "../data.json";
import { Cta } from '../Components/Cta';

export function AboutPage() {
    const navData = data.NavBar;
    const heroData = data.AboutPage.hero;
    const sideData = data.SideBar;
    const workFlowData = data.AboutPage.workFlow;
    const priceData = data.AboutPage.price;
    const aboutData = data.AboutPage.about;
    const ctaData = data.AboutPage.cta;
    const contactData = data.AboutPage.contact;

    return (
        <div className="about" style={{ backgroundColor: data.AboutPage.backgroundColor }}>
            <Helmet>
                <title>Sobre | Delleprane Studio</title>
                <meta name="description" content="Saiba mais sobre o Delleprane Studio, nossa história, missão, e como podemos ajudar seu negócio a crescer com soluções criativas e personalizadas." />
                <meta name="keywords" content="sobre, studio, design, desenvolvimento web, marketing digital, soluções criativas" />
                <meta property="og:title" content="Sobre | Delleprane Studio" />
                <meta property="og:description" content="Conheça o Delleprane Studio, nossa missão e visão, e descubra como nossas soluções criativas podem impulsionar seu negócio." />
                <meta property="og:image" content={heroData.image} />
                <meta property="og:url" content="https://www.delleprane.com.br/about" />
            </Helmet>

            <div className="about-container">
                <NavBar navData={navData} />
                <Hero hero={heroData} />
                <About data={aboutData} />
                <Price data={priceData} />
                <WorkFlow data={workFlowData} />
                <Cta data={ctaData}/>
                <Contact data={contactData} />
                <SideBar sideData={sideData} />
            </div>
        </div>
    );
}
